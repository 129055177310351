





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Card } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';
import { getFileObjectByUrl } from '@/utils/filehelper';

/**
 * PageCardList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'home-page-card-form-dialog': () => import('./HomePageCardFormDialog.vue')
    }
})
export default class HomePageCardList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all pageCards
    @Prop({ type: Array as () => Array<Card> }) readonly pageCards: Array<Card> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited pageCard
    private editedPageCardItem: Card = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        title: '',
        content: '',
        backgroundColor: 'transparent',
        color: '#000000FF',
        button: {
            id: -1,
            routeUrl: '',
            backgroundColor: '',
            color: '#3f51b5FF',
            content: ''
        }
    };

    // Default pageCard item values
    private defaultPageCardItem: Card = {
        id: 0,
        image: {
            id: 0,
            src: '',
            obj: undefined
        },
        title: '',
        content: '',
        backgroundColor: '#00000000',
        color: '#00000000',
        button: {
            id: 0,
            routeUrl: '',
            backgroundColor: '',
            color: '#3f51b5FF',
            content: ''
        }
    }

    // The index of the edited pageCard (-1 = new pageCard)
    private editedPageCardIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.title').toString(),
                value: 'title'
            },
            {
                text: this.$t('table.headers.content').toString(),
                value: 'content'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the PageCardFormDialog to create a new pageCard
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addPageCard() {
        this.editedPageCardItem = CloneHelper.deepCloneObject({...this.defaultPageCardItem });

        // get max id's
        let maxCustomerInformationId = -1;
        let maxImageId = -1;

        if (this.pageCards) {
            this.pageCards.forEach(card => {
                if (card.id > maxCustomerInformationId) {
                    maxCustomerInformationId = card.id;
                }

                if (card.image.id > maxImageId) {
                    maxImageId = card.image.id;
                }
            });
        }

        this.editedPageCardItem.id = maxCustomerInformationId +1;
        this.editedPageCardItem.image.id = maxImageId +1;

        this.editedPageCardIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the PageCardFormDialog to edit the given pageCard element
     * 
     * @param pageCardItem pageCard item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async editPageCard(pageCardItem: Card) {
        if (this.pageCards) {
            const card = CloneHelper.deepCloneObject(pageCardItem);

            if (pageCardItem.image.src) {
                const imageUrl = process.env.VUE_APP_API_URL + pageCardItem.image.src;
                const imageExtension = pageCardItem.image.src.split('.').pop();
                const imageObj = await getFileObjectByUrl(
                    imageUrl, 
                    `home-card-${pageCardItem.id}.${imageExtension}`
                );

                card.image.obj = imageObj;
            }

            this.editedPageCardItem = card;
            this.editedPageCardIndex = this.pageCards.indexOf(pageCardItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given pageCard
     * 
     * @param pageCardItem pageCard item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async deletePageCard(pageCardItem: Card) {
        if (this.pageCards) {
            const card = CloneHelper.deepCloneObject(pageCardItem);

            if (pageCardItem.image.src) {
                const imageUrl = process.env.VUE_APP_API_URL + pageCardItem.image.src;
                const imageExtension = pageCardItem.image.src.split('.').pop();
                const imageObj = await getFileObjectByUrl(
                    imageUrl, 
                    `home-card-${pageCardItem.id}.${imageExtension}`
                );

                card.image.obj = imageObj;
            }

            this.editedPageCardItem = card;
            this.editedPageCardIndex = this.pageCards.indexOf(pageCardItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedPageCard(formDialogMode: string, pageCardItemIndex: number, pageCardItem: Card) {
        if (this.pageCards) {
            if (formDialogMode == 'create') {
                this.pageCards.push(pageCardItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.pageCards[pageCardItemIndex], pageCardItem);
            }
            else if (formDialogMode == 'delete') {
                this.pageCards.splice(pageCardItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
